import ICloudinaryConfigurations from '@cloudinary/url-gen/config/interfaces/Config/ICloudinaryConfigurations';
import { BrowserOptions } from '@sentry/browser';
import { CookieAttributes } from 'js-cookie';
import get from 'lodash/get';
const { version } = require('../../package.json');

export type EnvironmentString =
  | 'test'
  | 'development'
  | 'staging'
  | 'production';

export type Config = {
  environment: EnvironmentString;
  port: number;
  isBrowser: boolean;
  apiEndpoint: string;
  cdnEndpoint: string;
  graphQLEndpoint: string;
  defaultLanguage: string;
  acceptedLanguages: string[];
  sentry: BrowserOptions;
  currency: {
    default: {
      code: 'EUR';
    };
  };
  ssl: {
    redirect: boolean;
  };
  auth: {
    cookieOptions: Partial<CookieAttributes>;
  };
  food: {
    temperature: {
      hotSlug: string;
      coldSlug: string;
    };
  };
  googleAnalyticsTrackingID: string;
  foodlesLandingPageDomain: string;
  mobileAppListings: {
    googlePlay: {
      [language: string]: string;
    };
    appStore: {
      [language: string]: string;
    };
  };
  intercomApiKey: string;
  cloudinary: ICloudinaryConfigurations;
  smartlookProjectKey: string;
};

export const APP_NAME = 'customer-web-app';
const environment: EnvironmentString = (process.env.NODE_ENV ||
  /* istanbul ignore next */
  'development') as EnvironmentString;
const sentryEnvironment = process.env.SENTRY_ENVIRONMENT || 'Dev';

const release = `${version}-${(
  process.env.HEROKU_SLUG_COMMIT || 'dev'
).substring(0, 6)}`;
const apiEndpoint = process.env.API_ENDPOINT || 'http://localhost:8000/api';
const cdnEndpoint = process.env.CDN_ENDPOINT || 'https://cdn.foodles.co';
const graphQLEndpoint =
  process.env.GRAPHQL_ENDPOINT || 'http://localhost:3000/graphql';

const config: Config = {
  environment,
  apiEndpoint,
  cdnEndpoint,
  graphQLEndpoint,
  port: parseInt(process.env.PORT || '4000', 10),
  isBrowser: get(process, 'browser', false) === true,
  defaultLanguage: process.env.DEFAULT_LANGUAGE || 'fr',
  acceptedLanguages: (process.env.ACCEPTED_LANGUAGES || 'fr,en').split(','),
  sentry: {
    release,
    environment: sentryEnvironment,
    dsn: process.env.SENTRY_DSN,
    dist: process.env.HEROKU_SLUG_COMMIT || 'dev',
  },
  ssl: {
    redirect: process.env.SSL_REDIRECT === 'true',
  },
  auth: {
    cookieOptions: {
      domain: process.env.AUTH_COOKIE_DOMAIN || 'localhost',
    },
  },
  currency: {
    default: {
      code: 'EUR',
    },
  },
  food: {
    temperature: {
      hotSlug: process.env.FOOD_HOT_TAG || 'chaud',
      coldSlug: process.env.FOOD_COLD_TAG || 'froid',
    },
  },
  googleAnalyticsTrackingID: process.env.GOOGLE_ANALYTICS_TRACKING_ID || '',
  foodlesLandingPageDomain:
    process.env.LANDING_PAGE || 'https://www.foodles.co',
  mobileAppListings: {
    googlePlay: {
      en:
        'https://play.google.com/store/apps/details?id=co.foodles.customerapp.v2&hl=en',
      fr:
        'https://play.google.com/store/apps/details?id=co.foodles.customerapp.v2&hl=fr',
    },
    appStore: {
      en: 'https://apps.apple.com/us/app/foodles/id1460002316?ls=1',
      fr: 'https://apps.apple.com/fr/app/foodles/id1460002316?ls=1',
    },
  },
  intercomApiKey: process.env.INTERCOM_API_KEY || '',
  cloudinary: {
    cloud: {
      cloudName: process.env.CLOUDINARY_CLOUD_NAME || 'foodles',
    },
  },
  smartlookProjectKey: process.env.SMARTLOOK_PROJECT_KEY || '',
};

export default config;
