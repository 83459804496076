import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { NextPageContext } from 'next';
import cookies from 'next-cookies';
import Router from 'next/router';

import * as apollo from '../core/apollo';
import config from '../core/config';
import * as sentryHelper from '../core/helper/sentry';
import { Routes } from '../typings';

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export async function logoutWithoutRedirect(): Promise<void> {
  if (!config.isBrowser) return;

  const client = apollo.getClient();
  if (client.cache) {
    await client.cache.reset();
  }
  try {
    await client.mutate({ mutation: LOGOUT_MUTATION });
  } catch (err) {
    sentryHelper.captureException(err);
  }

  window.Intercom('shutdown');
}

export async function logout(): Promise<void> {
  if (!config.isBrowser) return;

  const client = apollo.getClient();
  if (client.cache) {
    await client.cache.reset();
  }

  try {
    await client.mutate({ mutation: LOGOUT_MUTATION });
  } catch (err) {
    sentryHelper.captureException(err);
  }
  window.Intercom('shutdown');

  await Router.push(Routes.SIGNIN);
}

export function isAuthenticated(ctx: NextPageContext): boolean {
  const { sessionid, isloggedin, csrftoken } = cookies(ctx);
  return (!isEmpty(isloggedin) || !isEmpty(sessionid)) && !isEmpty(csrftoken);
}
